<template>
  <div class="intercept">
    <el-dialog
      :visible.sync="interceptVisible"
      :before-close="handleClose"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="interceptVisible"
      title="似乎犹豫了一小下？"
      center>
      <div class="intercept-cont">
        <div class="pay-cont">
          <span class="pay-top-tip">价格太贵？</span>
          <div class="pay-info">
            <span>特意为你补偿了新人</span>
            <span class="discount">折扣</span>
            <span>，限时5分钟，关闭窗口失效！还剩</span>
            <span class="time">{{ minutes }}</span>
            <span>分</span>
            <span class="time">{{ seconds.toString().padStart(2, '0') }}</span>
            <span>秒</span>
          </div>
          <div class="pay-cont-img" v-if="orderDetail">
            <div class="pay-img">
              <div v-if="orderDetail.orderInfo.qrCode">
                <generateQRCode :url="orderDetail.orderInfo.qrCode"></generateQRCode>
              </div>
            </div>
            <div class="pay-text">
              <div class="item-text">
                <p>原价：<span class="i-num">{{ orderDetail.orderInfo.showPrice }}元</span></p>
                <i class="el-icon-d-arrow-right"></i>
                <p>骨折价：<span class="color">{{ orderDetail.orderInfo.price }}元</span></p>
              </div>
              <div class="pay-tip">
                <img alt="" src="@/assets/images/icon/wx-pay2.png">
                <img alt="" src="@/assets/images/icon/zfb-pay2.png">
                <p>现在扫码支付，可享受累计<span>{{preferentialPrice}}元</span>减免</p>
              </div>
            </div>
          </div>
        </div>
        <div class="intercept-tags" v-if="otherIdsLength > 0">
          <div class="tags-item col1">还是觉得贵？</div>
          <div class="tags-item col2" @click="openWord">
            <i class="el-icon-data-analysis"></i>
            预览生成效果
          </div>
        </div>
        <div class="pay-cont pay-border0" v-if="otherIdsLength > 0">
          <div class="pay-fuwu" v-if="!postValue">
            <div class="f-l">
              <i class="el-icon-document-checked"></i>
              检测到你勾选了
              <span class="t">{{ otherIdsLength }}项</span>
              增值服务，全部取消最高
              <span class="c">可再减{{ otherIdsPrice }}元</span>
            </div>
            <div class="f-r" @click="postCancelAndRestore(false)">一键全部取消</div>
          </div>
          <div class="pay-fuwu" v-else>
            <div class="f-l">
              <span class="t">{{ otherIdsLength }}项</span>
              增值服务已全部取消
            </div>
            <div class="f-r" @click="postCancelAndRestore(true)">点击恢复</div>
          </div>
        </div>

        <div class="tags-btn" v-if="otherIdsLength <= 0" @click="openWord">
          <i class="el-icon-data-analysis"></i>
          预览生成效果
        </div>
      </div>
    </el-dialog>

    <ExampleDocument ref="example"></ExampleDocument>
  </div>
</template>
<script>
import generateQRCode from "@/components/generateQRCode/generateQRCode.vue"
import ExampleDocument from "@/components/ExampleDocument/index.vue"
import CommonApi from "@/api/common"

export default {
  components: {ExampleDocument, generateQRCode},
  props: ['otherIdsLength','otherIdsPrice'],
  data() {
    return {
      interceptVisible: false,
      orderDetail: '',
      exampleId: '',
      timeLeft: 5 * 60, // 5 minutes in seconds
      intervalId: null,
      timerIntercept: null,
      postValue: false
    }
  },
  created() {
  },
  computed: {
    preferentialPrice(){
      let num = this.orderDetail.orderInfo.showPrice - this.orderDetail.orderInfo.price
      num = num.toFixed(2)
      return num
    },
    minutes() {
      return Math.floor(this.timeLeft / 60)
    },
    seconds() {
      return this.timeLeft % 60
    }
  },
  mounted() {
  },
  beforeDestroy() {
    if (this.intervalId) {
      window.clearInterval(this.intervalId)
      this.intervalId = null
    }
    if(this.timerIntercept){
      window.clearInterval(this.timerIntercept)
      this.timerIntercept = null
    }
  },
  methods: {
    postCancelAndRestore(type){
      let _this = this
      let data = {
        orderId: _this.orderDetail.orderInfo.id,
        select: type
      }
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = _this.$md5(setOf)
      CommonApi.postCancelAndRestore(data, key, time, infoMd5).then(res => {
        // console.log(res.data)
        _this.orderDetail.orderInfo = res.data
        _this.postValue = !_this.postValue
      }).catch(() => {
      })
    },
    openIntercept(data,id){
      this.orderDetail = data
      this.exampleId = id
      this.startCountdown()
      this.getTimeFun(this.orderDetail.orderInfo.id)
      this.interceptVisible = true
    },
    handleClose(done){
      setTimeout(()=>{
        if (this.intervalId) {
          window.clearInterval(this.intervalId)
          this.intervalId = null
        }
        if(this.timerIntercept){
          window.clearInterval(this.timerIntercept)
          this.timerIntercept = null
        }
        this.$emit('infoInterceptValue', true)
        this.interceptVisible = false
      },200)
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     // console.log(this.intervalId, '+' ,this.timerIntercept)
      //     if (this.intervalId) {
      //       window.clearInterval(this.intervalId)
      //       this.intervalId = null
      //     }
      //     if(this.timerIntercept){
      //       window.clearInterval(this.timerIntercept)
      //       this.timerIntercept = null
      //     }
      //     this.$emit('infoInterceptValue', true)
      //     this.interceptVisible = false
      //     done();
      //   })
      //   .catch(_ => {});
    },
    openWord(){
      CommonApi.example(this.exampleId).then(res => {
        this.$refs.example.openDialog(res.data)
      }).catch(() => {
      })
    },
    startCountdown() {
      this.intervalId = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.handleClose()
        }
      }, 1000);
    },
    // 查询订单支付状态
    getQueryPayState(orderId) {
      let _this = this
      CommonApi.queryPayState(orderId).then(res => {
        // _this.orderCont = res.data
        if (res.data) {
          //清除定时器查询
          clearInterval(this.timerIntercept)
          //清除缓存订单查询号
          localStorage.removeItem('orderId')
          localStorage.removeItem('creationDocCode')
          //跳转传参
          this.$router.push({ path: _this.paySuccessUrl + '?info=' + orderId })
        }
      }).catch(() => {
      })
    },
    //定时器查询订单状态
    getTimeFun(id) {
      this.timerIntercept = setInterval(() => {
        this.getQueryPayState(id)
      }, 3000)
    },
  }
}
</script>
<style scoped lang="less">
.intercept {
  .interceptVisible{
    /deep/ .el-dialog {
      position: relative;
      padding: 10px 0;
      width: 800px !important;
      border-radius: 15px;
      background-color: #fffaf2;
      //background: linear-gradient(180deg, #ebf1fc, #fff);
      .el-dialog__header {
        border-bottom: 0;
        .el-dialog__title{
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .intercept-cont{
      padding: 0 20px;
      .pay-cont{
        position: relative;
        padding-top: 30px;
        box-shadow: 0 5px 10px rgba(60,52,44,.08);
        border-radius: 12px;
        border: 1px solid #f0e6dd;
        background-color: #ffffff;
        .pay-top-tip{
          position: absolute;
          top: 1px;
          left: 1px;
          padding: 5px 10px;
          background-color: #edf3fc;
          border-radius: 11px 0 11px 0;
          color: #7093e0
        }
        .pay-info{
          width: 80%;
          margin: 20px auto;
          height: 40px;
          background-color: #fa8f23;
          border-radius: 6px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          span{
            color: #ffffff;
          }
          span.discount{
            padding: 1px 5px;
            margin: 0 5px;
            background-color: #ffffff;
            border-radius: 5px;
            color: #fa8f23;
          }
          span.time{
            padding: 1px 5px;
            margin: 0 5px;
            background-color: #3c3c3c;
            border-radius: 5px;
            color: #ffffff;
          }
        }
        .pay-cont-img{
          width: 80%;
          margin: 0 auto;
          padding: 15px 0 30px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          .pay-img {
            position: relative;
            margin-right: 20px;
            border-radius: 15px;
            border: none;
            > div {
              padding: 10px;
              border: 1px solid #e6e6e6;
              img {
                width: 124px;
                height: 124px;
                padding: 0;
                margin: 0;
                border: 0;
                background-color: #ffffff;
              }
            }
            > div:before, > div:after {
              content: '';
              position: absolute;
              width: 10px;
              height: 10px;
            }
            > div:before {
              bottom: 0;
              left: 0;
              border-bottom: 2px solid #36b7e6;
              border-left: 2px solid #36b7e6;
            }
            > div:after {
              bottom: 0;
              right: 0;
              border-bottom: 2px solid #36b7e6;
              border-right: 2px solid #36b7e6;
            }
          }
          .pay-img:before,.pay-img:after {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
          }
          .pay-img:before {
            top: 0;
            left: 0;
            border-top: 2px solid #36b7e6;
            border-left: 2px solid #36b7e6;
          }
          .pay-img:after {
            top: 0;
            right: 0;
            border-top: 2px solid #36b7e6;
            border-right: 2px solid #36b7e6;
          }
          .pay-text{
            .item-text{
              margin-bottom: 30px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              p{
                height: 32px;
                line-height: 32px;
                padding: 0 12px;
                font-size: 14px;
                color: #312d2a;
                background: linear-gradient(94deg,#fcf6eb,rgba(252,246,235,0));
                border-radius: 4px;
                display: flex;
                align-items: center;
                span.i-num{
                  text-decoration: line-through;
                }
                span.color{
                  color: #ff4e4e;
                  font-size: 20px;
                  font-weight: bold;
                }
              }
              i{
                margin: 0 10px;
                font-size: 18px;
                color: #fa8f23;
              }
            }
            .pay-tip {
              margin-top: 10px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
              }
              p {
                span {
                  font-size: 14px;
                  color: #409EFF;
                }
              }
            }
          }
        }
        .pay-fuwu{
          width: 90%;
          margin: 0 auto;
          padding: 40px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .f-l{
            color: #101010;
            i{
              color: #fa8f23;
            }
            span.t{
              text-decoration: underline;
            }
            span.c{
              color: #fa8f23;
            }
          }
          .f-r{
            padding: 6px 15px;
            border-radius: 6px;
            border: 1px solid #fa8f23;
            color: #fa8f23;
            cursor: pointer;
          }
          .f-r:hover{
            color: #ffffff;
            background-color: #fa8f23;
          }
        }
      }
      .pay-border0{
        padding-top: 0;
        border: 0;
        border-radius: 0 0 12px 12px;
      }
      .intercept-tags{
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .tags-item{
          padding: 8px 14px;
          margin-right: 10px;
          border-radius: 8px 8px 0 0;
          border: 2px solid #ffffff;
          cursor: pointer;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
        .col1{
          background-color: #edf3fc;
          color: #7093e0
        }
        .col2{
          background-color: #fff5eb;
          color: #3c3c3c
        }
      }
      .tags-btn{
        width: 160px;
        height: 40px;
        margin: 30px auto 0 auto;
        font-size: 14px;
        color: #fa8f23;
        border-radius: 8px;
        border: 1px solid rgba(251, 200, 169, 0.5);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
          margin-right: 5px;
        }
      }
      .tags-btn:hover{
        background-color: #fff5eb;
      }
    }
  }
}
</style>
