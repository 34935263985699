<template>
<div>
  <el-dialog
    title="示例文档"
    :visible.sync="exeDialogVisible"
    :modal-append-to-body="false"
    class="dialog-box"
    @close="closePreview"
    :destroy-on-close="true"
    center>
    <div class="exe-box">
      <div class="exe-menu">
        <ul v-if="navList && navList.length > 0">
          <li v-for="item in navList"
              :class="{ 'active': activeAnchorId == item.anchor }"
              @click="goAnchor(item.anchor)">{{item.title}}</li>
        </ul>
      </div>
      <div class="exe-cont" v-if="exeItems && exeItems.length > 0" @scroll="handleScroll">
        <div :id="item.anchor" class="img-box" v-for="item in exeItems" ref="exeBoxes"  >
          <img :src="item.img" alt="">
        </div>
      </div>
    </div>
  </el-dialog>
</div>
</template>
<script>
export default {
  data() {
    return {
      exeDialogVisible: false,
      activeAnchorId: '',
      navList: [],
      exeItems: []
    }
  },
  created() {
  },
  methods: {
    openDialog(data) {
      // console.log(data)
      let _this = this;
      data.forEach((item,index) => {
        _this.navList.push({
          title: item.title,
          anchor: 'chapter' + index
        })
        _this.exeItems.push({
          img: item.imageUrl,
          anchor: 'chapter' + index
        })
      })
      this.exeDialogVisible = true
    },
    closePreview(){
      this.navList = []
      this.exeItems = []
    },
    // 导航到指定的锚点
    goAnchor(anchor) {
      // 更新当前激活的锚点ID
      this.activeAnchorId = anchor;
      // 滚动到对应的元素
      this.$nextTick(() => {
        const element = document.getElementById(anchor);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      })
    },
    handleScroll(event) {
      const container = event.target;
      const boxes = this.$refs.exeBoxes;
      let activeIndex = 0;
      // 遍历所有内容块，找到第一个位于视口内的内容块
      for (let i = 0; i < boxes.length; i++) {
        const box = boxes[i];
        const boxTop = box.offsetTop;
        const boxBottom = boxTop + box.offsetHeight;
        // 检查内容块是否在视口内（这里简单检查是否在视口顶部或之上）
        if (boxTop <= container.scrollTop + container.clientHeight) {
          activeIndex = i;
        } else {
          // 一旦找到一个不在视口内的内容块，就停止遍历
          break;
        }
      }
      // 更新活动锚点索引
      this.activeAnchorId = 'chapter' + activeIndex;
    }
  }
}
</script>
<style scoped lang="less">
.dialog-box{
  /deep/ .el-dialog{
    margin-top: 5vh !important;
    height: 85vh !important;
    .el-dialog__header{
      height: 56px !important;
      line-height: 56px !important;
      padding: 0 20px !important;
      .el-dialog__title{
        font-size: 16px !important;
      }
    }
    .el-dialog__body{
      height: calc(100% - 56px) !important;
      padding: 0 !important;
    }
  }
  .exe-box{
    position: relative;
    height: 100%;
    .exe-menu{
      position: absolute;
      top: 0;
      left: -136px;
      width: 134px;
      padding: 5px;
      border-radius: 10px 0 0 10px;
      background-color: #ffffff;
      ul{
        li{
          height: 34px;
          border-radius: 6px;
          line-height: 34px;
          padding: 0 20px;
          text-align: center;
          cursor: pointer;
        }
        li:hover{
          background-color: #f2f6ff;
        }
        .active{
          background-color: #f2f6ff;
          color: #467af2;
        }
      }
    }
    .exe-cont{
      height: 100%;
      padding: 10px 20px;
      overflow: auto;
      .img-box{
        width: 750px;
        margin: 0 auto 14px auto;
        background: #ffffff;
        box-shadow: 0 0 8px 1px rgba(0,0,0,.16);
        img{
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 900px){
  .dialog-box {
    /deep/ .el-dialog {
      width: 90%;
      margin-top: 5vh !important;
      height: 85vh !important;
    }
    .exe-box {
      .exe-menu {
        left: 0;
        width: 80px;
        ul {
          li {
            padding: 0;
            font-size: 10px;
          }
        }
      }
    }
  }
}
</style>
